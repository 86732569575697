<template>
  <div>
    <div class="header-box fixed-top">
      <!-- <div class="header-hint">
        <p>
          <router-link to="/my/HongKongStockWarrants"
            >Warrants over Hong Kong listed technology stocks now listed in
            Malaysia. Click to learn more.
          </router-link>
        </p>
        <button type="button" class="close" id="hintBut">&times;</button>
      </div> -->
      <!-- 固定在浏览器顶部的导航栏 -->
      <nav class="navbar row header-navbat" id="navbarHead">
        <!-- Brand -->
        <router-link
          id="header-navber-logo"
          class="navbar-brand navbar-logo"
          to="/my/Home"
        >
          <img src="image/rhb_logo.png" alt width="100%" height="100%" />
        </router-link>
        <div class="col p-0 nav_dan">
          <ul class="navbar-ul row" style="padding-right: 0">
            <!-- <li class="col-1" style="padding: 0;"></li> -->
            <li class="nav-item dropdown col-sm" style="padding: 0">
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                Toolbox
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/my/WarrantSearch"
                  >Warrant Search</router-link
                >
                <router-link class="dropdown-item" to="/my/WarrantDetails"
                  >Warrant Details</router-link
                >
                <router-link class="dropdown-item" to="/my/WarrantMatrix"
                  >Warrant Matrix</router-link
                >
                <router-link class="dropdown-item" to="/my/WarrantCalculator"
                  >Warrant Calculator</router-link
                >
                <router-link class="dropdown-item" to="/my/UnderlyingChart"
                  >Underlying Chart</router-link
                >
              </div>
            </li>
            <li
              class="nav-item dropdown col-sm"
              style="padding: 0; padding-right: 10px"
            >
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                Ideas
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/my/WarrantInFocus"
                  >Warrant in Focus</router-link
                >
                <router-link class="dropdown-item" to="/my/WarrantOfTheDay"
                  >Warrants of the Day</router-link
                >
                <router-link class="dropdown-item" to="/my/MarketMovers"
                  >Market Movers</router-link
                >
                <!-- <router-link
                  class="dropdown-item"
                  to="/my/UniqueWarrants"
                  >Unique Warrants</router-link
                > -->
              </div>
            </li>
            <li
              class="nav-item dropdown col-sm"
              style="padding: 0; padding-right: 37px"
            >
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                Workshop
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/my/LearnWarrants"
                  >Learn Warrants</router-link
                >
                <router-link class="dropdown-item" to="/my/Seminar"
                  >Seminar</router-link
                >
                <router-link class="dropdown-item" to="/my/Campaign"
                  >Campaign</router-link
                >
                <router-link class="dropdown-item" to="/my/Gallery"
                  >Gallery</router-link
                >
                <router-link class="dropdown-item" to="/my/Video"
                  >Video</router-link
                >
              </div>
            </li>
            <li
              class="nav-item dropdown col-sm"
              style="padding: 0; padding-right: 30px"
            >
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                Announcement
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/my/WarrantShout-Out"
                  >Warrant Shout-Out</router-link
                >
                <router-link class="dropdown-item" to="/my/NewWarrants"
                  >New Warrants</router-link
                >
                <router-link class="dropdown-item" to="/my/ExpiringWarrants"
                  >Expiring Warrants</router-link
                >
                <router-link class="dropdown-item" to="/my/Document"
                  >Warrant Documents</router-link
                >
              </div>
            </li>
            <li class="nav-item dropdown col-sm" style="padding: 0">
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                Connect
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/my/SignMeUp"
                  >Sign Me Up</router-link
                >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#RhbModal"
                  >RHB Tradesmart</a
                >
                <!-- <a class="dropdown-item" href="https://www.facebook.com/groups/MorningCuppa/" target="_blank">RHB Facebook
                Cuppa</a>-->
                <router-link class="dropdown-item" to="/my/Contactus"
                  >Contact Us</router-link
                >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#Telegram"
                  >Telegram Channel</a
                >
                <!-- <router-link class="dropdown-item" to="/my/AntGroup"
                  >ANT GROUP</router-link
                > -->
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="HongKong_but">
        <router-link to="/my/HongKongStockWarrants">Hong Kong Stock Warrants now on Bursa Malaysia</router-link>
        </div>-->

        <!-- <div class="navbar-logo"></div> -->
        <div
          class="navbar-toggler"
          data-toggle="collapse"
          id="closebtn"
          style="
            border: none;
            transform: translateY(2px);
            cursor: pointer;
            display: none;
          "
        >
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
        <!-- <div class="navber-close" id="exit" style="display: none;"><span class="glyphicon glyphicon-remove"
        aria-hidden="true"></span></div>-->
      </nav>
    </div>

    <div
      id="mint"
      style="
        position: fixed;
        right: 0px;
        bottom: 0;
        z-index: 10000;
        background-color: #fff;
        width: 100%;
        height: 0;
      "
    >
      <nav
        class="navbar"
        style="
          padding: 0;
          display: none;
          height: 90vh;
          align-items: normal;
          flex-direction: column;
        "
        id="mint-nav"
      >
        <ul
          class="navbar-nav font_cand"
          style="width: 100%; padding-top: 15px; flex: 1; overflow-x: scroll"
        >
          <li class="nav-item index-mint-li" @click="clickNav()">
            <router-link class="nav-link index-mint-text" to="/my/Home"
              >Home</router-link
            >
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-one"
            >
              Toolbox
              <span class="index-mint-arrows index-mint-arrows-one">
                <span
                  class="glyphicon glyphicon-menu-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-one"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantSearch"
                  >Warrant Search</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantDetails"
                  >Warrant Details</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantMatrix"
                  >Warrant Matrix</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantCalculator"
                  >Warrant Calculator</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/UnderlyingChart"
                  >Underlying Chart</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-two"
            >
              Ideas
              <span class="index-mint-arrows index-mint-arrows-two">
                <span
                  class="glyphicon glyphicon-menu-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-two"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantInFocus"
                  >Warrant in Focus</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantOfTheDay"
                  >Warrants of the Day</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/MarketMovers"
                  >Market Movers</router-link
                >
              </li>
              <!-- <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/UniqueWarrants"
                  >Unique Warrants</router-link
                >
              </li> -->
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-three"
            >
              Workshop
              <span class="index-mint-arrows index-mint-arrows-three">
                <span
                  class="glyphicon glyphicon-menu-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-three"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/LearnWarrants"
                  >Learn Warrants</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/my/Seminar"
                  >Seminar</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/my/Campaign"
                  >Campaign</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/my/Gallery"
                  >Gallery</router-link
                >
              </li>
               <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/my/Video"
                  >Video</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-four"
            >
              Announcement
              <span class="index-mint-arrows index-mint-arrows-four">
                <span
                  class="glyphicon glyphicon-menu-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-four"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/WarrantShout-Out"
                  >Warrant Shout-Out</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/NewWarrants"
                  >New Warrants</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/ExpiringWarrants"
                  >Expiring Warrants</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/my/Document"
                  >Warrant Documents</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <a
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-five"
            >
              Connect
              <span class="index-mint-arrows index-mint-arrows-five">
                <span
                  class="glyphicon glyphicon-menu-down"
                  aria-hidden="true"
                ></span>
              </span>
            </a>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-five"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/my/SignMeUp"
                  >Sign Me Up</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <a
                  class="nav-link index-mint-text2"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#RhbModal"
                  >RHB Tradesmart</a
                >
              </li>
              <!-- <li class="nav-item index-mint-li2">
            <a class="nav-link index-mint-text2" href="https://www.facebook.com/groups/MorningCuppa/" target="_blank">RHB
              Facebook
              Cuppa</a>
              </li>-->
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/Contactus"
                  >Contact Us</router-link
                >
              </li>
              <!-- <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/my/AntGroup"
                  >ANT GROUP</router-link
                >
              </li> -->
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <a
                  class="nav-link index-mint-text2"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#Telegram"
                  >Telegram Channel</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="modal fade" id="RhbModal">
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 900px; max-height: 450px"
      >
        <div class="modal-content">
          <button type="button" class="close Rhb_close" data-dismiss="modal">
            &times;
          </button>
          <!-- https://www.rhbtradesmart.com -->
          <!-- 模态框主体 -->
          <a href="https://www.rhbtradesmart.com/" target="_blank">
            <picture>
              <source srcset="/image/rhb_img.webp" type="image/webp" />
              <img src="/image/rhb_img.png" alt width="100%" />
            </picture>
          </a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="Telegram">
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 900px; max-height: 450px"
      >
        <div class="modal-content">
          <button type="button" class="close Rhb_close" data-dismiss="modal">
            &times;
          </button>
          <a href="https://t.me/rhbwarrantmalaysia" target="_blank">
            <!-- <source
              srcset="../../../public/image/telegram_img.webp"
              type="image/webp"
            /> -->
            <!-- <img
              src="../../../public/image/telegram_img.webp"
              alt
              width="100%"
            /> -->
            <picture>
              <source
                srcset="../../../public/image/telegram_img.webp"
                type="image/webp"
              />
              <img src="../../../public/image/telegram_img.jpg" alt="banner" />
            </picture>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { headerIncident } from "./Header.js";
export default {
  name: "Header",
  created() {},
  mounted() {
    let _this = this;
    this.$nextTick(function () {
      headerIncident();
      _this.$js.ScreenMonitor();
    });
  },
  methods: {
    // 点击菜单(手机)
    clickNav: function () {
      $("#mint").css({ height: "0" });
      $("#mint-nav").slideUp();
      $(".navbar-toggler").removeClass("is--active");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import './Header.css';
</style>
