<template>
  <!-- Footer -->
  <footer class="page-footer font-small indigo" style="background-color:#fff;">
    <!-- Footer Links -->
    <div
      class="pt-4 pb-5 font_cand_R container-fluid mobile_fluid"
      style="background-color: rgba(242, 242, 242, 1);"
    >
      <div style="padding: 0 5%;" class="mobileC">
        <!-- Grid row -->
        <div class="row" id="showFooter">
          <div class="col-md mx-auto" style="padding: 0;">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">TOOLBOX</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/WarrantSearch">Warrant Search</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/WarrantDetails">Warrant Details</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/WarrantMatrix">Warrant Matrix</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/my/WarrantCalculator"
              >Warrant Calculator</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/my/UnderlyingChart"
              >Underlying Chart</router-link>
            </h6>
          </div>
          <div class="col-md mx-auto" style="padding: 0;">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">IDEAS</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/WarrantInFocus">Warrant in Focus</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/my/WarrantOfTheDay"
              >Warrants of the Day</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/MarketMovers">Market Movers</router-link>
            </h6>
            <!-- <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/my/UniqueWarrants"
              >Unique Warrants</router-link>
            </h6> -->
          </div>
          <div class="col-md mx-auto" style="padding: 0;">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-3 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">WORKSHOP</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/LearnWarrants">Learn Warrants</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/Seminar">Seminar</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/Campaign">Campaign</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/Gallery">Gallery</router-link>
            </h6>
             <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/Video">Video</router-link>
            </h6>
            <!-- <h6 class="font-weight-bold text-capitalize footer-h" style="margin-top: 40px;">
                        <a href="../my/disclaimer" class="footer-bold-a">Disclaimer</a>
            </h6>-->
          </div>
          <div class="col-md mx-auto" style="padding: 0;">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-3 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">ANNOUNCEMENT</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/my/WarrantShout-Out"
              >Warrant Shout-Out</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/NewWarrants">New Warrants</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/ExpiringWarrants">Expiring Warrants</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/my/WarrantDetails"
              >Warrant Documents</router-link>
            </h6>
            <!-- <h6 class="font-weight-bold text-capitalize footer-h" style="margin-top: 40px;">
                        <a href="../my/personalact" class="footer-bold-a"> Personal Act/PDPA</a>
            </h6>-->
          </div>
          <div class="col-md mx-auto" style="padding: 0;">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-3 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">CONNECT</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/SignMeUp">Sign Me Up</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#RhbModal"
                class="footer-light-a font_cand"
              >RHB Tradesmart</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/Contactus">Contact Us</router-link>
            </h6>
            <!-- <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/my/AntGroup">ANT GROUP</router-link>
            </h6> -->
            <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <a
                class="footer-light-a font_cand"
                href="javascript:;"
                data-toggle="modal"
                data-target="#Telegram"
              >Telegram Channel</a>
            </h6>
          </div>
        </div>
        <!-- Grid row -->

        <!-- 屏幕小于768px -->
        <div id="hideFooter" class="font_cand_R" style="display: none;">
          <!-- Blank -->
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left;"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-one">
                TOOLBOX
                <span class="footer-arrows">
                  <span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                </span>
              </a>
              <ul class="navbar-nav nav-ul" style="display: none;" id="FooterUl-one">
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/WarrantSearch">Warrant Search</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/WarrantDetails">Warrant Details</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/WarrantMatrix">Warrant Matrix</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/my/WarrantCalculator"
                  >Warrant Calculator</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/UnderlyingChart">Underlying Chart</router-link>
                </li>
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left;"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-two">
                IDEAS
                <span class="footer-arrows">
                  <span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                </span>
              </a>
              <ul class="navbar-nav nav-ul" style="display: none;" id="FooterUl-two">
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/WarrantInFocus">Warrant in Focus</router-link>
                </li>
                <li class="nav-item nav-li">
                  <!-- <a href="../my/warrantsoftheday">Warrants of the Day</a> -->
                  <router-link
                    class="footer-light-a-two"
                    to="/my/WarrantOfTheDay"
                  >Warrants of the Day</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/MarketMovers">Market Movers</router-link>
                </li>
                <!-- <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/my/UniqueWarrants"
                  >Unique Warrants</router-link>
                </li> -->
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left;"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-three">
                WORKSHOP
                <span class="footer-arrows">
                  <span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                </span>
              </a>
              <ul class="navbar-nav nav-ul" style="display: none;" id="FooterUl-three">
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/LearnWarrants">Learn Warrants</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/Seminar">Seminar</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/Campaign">Campaign</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/Gallery">Gallery</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/Video">Video</router-link>
                </li>
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left;"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-four">
                ANNOUNCEMENT
                <span class="footer-arrows">
                  <span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                </span>
              </a>
              <ul class="navbar-nav nav-ul" style="display: none;" id="FooterUl-four">
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/my/WarrantShout-Out"
                  >Warrant Shout-Out</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/NewWarrants">New Warrants</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/ExpiringWarrants">Expiring Warrants</router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/WarrantDetails">Warrant Documents</router-link>
                </li>
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left;"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-five">
                CONNECT
                <span class="footer-arrows">
                  <span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
                </span>
              </a>
              <ul class="navbar-nav nav-ul" style="display: none;" id="FooterUl-five">
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/SignMeUp">Sign Me Up</router-link>
                </li>
                <li class="nav-item nav-li">
                  <a
                    class="footer-light-a-two"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#RhbModal"
                  >RHB Tradesmart</a>
                </li>
                <!-- <li class="nav-item">
                                <a class="footer-light-a-two" href="https://www.facebook.com/groups/MorningCuppa/" target="_blank">RHB Facebook Cuppa</a>
                </li>-->
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/Contactus">Contact Us</router-link>
                </li>
                    <!-- <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/my/AntGroup">ANT GROUP</router-link>
                </li> -->
                <li class="nav-item">
                  <a
                    class="footer-light-a-two"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#Telegram"
                  >Telegram Channel</a>
                </li>
              </ul>
            </h6>
          </div>
          <!-- <div class="mx-auto" style="text-align: left;">
                    <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
                        <a href="https://www.rhbgroup.com/index.html" class="footer-bold-a" target="_blank">RHB
                            GROUP</a>
                    </h6>
                    <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
                        <a href="../my/disclaimer" class="footer-bold-a">DISCLAIMER</a>
                    </h6>
                    <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
                        <a href="../my/personalact" class="footer-bold-a">PERSONAL ACT/PDPA</a>
                    </h6>
          </div>-->
        </div>
      </div>
    </div>
    <!-- Footer Links -->
    <div style="padding: 0 15px;background-color: #F2F2F2;">
      <div class="footer_brand">
        <div
          style=" border-bottom: 1px solid #a0a0a0; padding-bottom: 20px;"
        >© 2020 RHB Bank Berhad 196501000373 (6171-M). All rights reserved.</div>
        <div style="padding-top: 20px;">
          <span>
            <a
              href="https://www.rhbgroup.com/index.html"
              class="footer-bold-a "
              target="_blank"
            >RHB Group</a>
          </span>
          <span>
            <router-link class="footer-bold-a" to="/my/Disclaimer">Disclaimer</router-link>
          </span>
          <span>
            <router-link class="footer-bold-a" to="/my/PersonalActPDPA">Personal Act/PDPA</router-link>
          </span>
          <span>
            <!-- <router-link class="footer-bold-a" to="/my/WebinarTerms">Webinar Terms & Conditions</router-link> -->
            <a href="https://warrant.rhbgroup.com/rhbresources/pdf/Warrant Webinar TC - FINAL 27.10.2020.pdf" target="_blank">Webinar Terms & Conditions</a>
          </span>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </footer>
</template>

<script>
import { FooterClick } from "./Footer.js";
export default {
  name: "Footer",
  created() {},
  mounted() {
    this.$nextTick(function() {
      FooterClick();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import './Footer.css';
</style>
