import Vue from "vue";
import router from "./router";
import store from "./store";
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui' //jq-ui
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// 引入bootstrap的icon
import 'bootstrap-icons/font/bootstrap-icons.css'
import axios from 'axios'
Vue.prototype.$axios = axios
    // axios.defaults.baseURL = '/api'
    //boostrap图标
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
//解决css样式被覆盖
import Fonts from "./fonts.css";
import Css from "./assets/css/main.css";
import App from "./App.vue";
//全局引用公共js
import global from "./assets/js/global.js";
Vue.prototype.$js = global;
//引入anychart
import anychart from 'anychart';
Vue.prototype.$anychart = anychart;
// import Swiper styles
// import 'swiper/swiper-bundle.css';
// // core version + navigation, pagination modules:
// import Swiper, { Navigation, Pagination } from 'swiper';
// // configure Swiper to use modules
// Swiper.use([Navigation, Pagination]);

Vue.config.productionTip = false;
// Vue.prototype.$ = $
new Vue({
    router,
    store,
    Fonts,
    Css,
    $,
    render: h => h(App)
}).$mount("#app");
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    next()
})