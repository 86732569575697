import "./jquery-ui.min.js"
import {
    HeadButtonhide,
    HeadButtonshow
} from "../../components/Header/Header.js";
import {
    TakeOutFlushRight,
    AddFlushRight,
    MobileChange,
    PCChange
} from "../../components/Footer/Footer.js";
import { blackScholes } from "./black-scholes";
const ricArrst = [];
var anchorType;
export default {
    // API URL
    webservice: "https://warrant.rhbgroup.com/rhbwebservice/",
    //  webservice: "https://175.41.158.137/rhbwebservice/",
    // 监听屏幕
    ScreenMonitor: function () {
        // 监听屏幕
        var windowWidth = $(window).width();
        var formBoxW = $('.search-form-box').width();
        var forW = $('#searchForm').width();
        var headerH;
        var theadCloneH;
        if (windowWidth < 768 || windowWidth === 768) {
            HeadButtonhide();
            MobileChange();
            TakeOutFlushRight();
            $('#content').css({
                'margin-top': $('.header-box').height()
            })
            headerH = $(".header-box").innerHeight();
            theadCloneH = $(".header-box").innerHeight();
            $("#mint").css({
                top: headerH
            });
            anchorType = false
        } else {
            theadCloneH = $(".header-box").innerHeight();
            anchorType = true
            AddFlushRight();
            PCChange();
            $("#mint").css({ height: "0" });
            $("#mint-nav").slideUp();
            $("#closebtn").removeClass("is--active");
            HeadButtonshow();
            $('#content').css({
                'margin-top': $('.header-box').height()
            })
        }
        if (formBoxW < forW) {
            $('.search-form-box').css({
                'overflow-x': 'scroll'
            })
        } else {
            $('.search-form-box').css({
                'overflow-x': 'hidden'
            })
        }
        $('.search-select-one-r').css({
            'width': $('.search-select-one-text').width()
        })
        $('.search-select-two-r').css({
            'width': $('.search-select-two-text').width()
        })
        $(window).resize(function () {

            windowWidth = $(window).width();
            formBoxW = $('.search-form-box').width()
            forW = $('#searchForm').width()
            if (windowWidth < 768 || windowWidth === 768) {
                HeadButtonhide();
                TakeOutFlushRight();
                MobileChange();
                headerH = $(".header-box").innerHeight();
                $("#mint").css({
                    top: headerH
                });
                theadCloneH = $(".header-box").innerHeight();
                anchorType = false
                $('#content').css({
                    'margin-top': $('.header-box').height()
                })
            } else {
                theadCloneH = $(".header-box").innerHeight();
                anchorType = true
                AddFlushRight();
                PCChange();
                $("#mint").css({ height: "0" });
                $("#mint-nav").slideUp();
                $("#closebtn").removeClass("is--active");
                HeadButtonshow();
                $('#content').css({
                    'margin-top': $('.header-box').height()
                })
            }
            if (formBoxW < forW) {
                $('.search-form-box').css({
                    'overflow-x': 'scroll'
                })
            } else {
                $('.search-form-box').css({
                    'overflow-x': 'hidden'
                })
            }
            $('.search-select-one-r').css({
                'width': $('.search-select-one-text').width()
            })
            $('.search-select-two-r').css({
                'width': $('.search-select-two-text').width()
            })

            // $('.tgme_container_box').css({
            //   'width': ($('#ifr').width() - 19) + 'px'
            // })
        });
        this.geAllricData();
    },
    /*************** 锚点 ******************/
    SetAnchor: function (location) {
        let num
        if (anchorType) {
            $('html, body').animate({ scrollTop: 0 }, 1)
        } else {
            if (!localStorage.getItem("alertbar")) {
                num = location - 350
                $('html, body').animate({ scrollTop: num }, 1)
            } else {
                $('html, body').animate({ scrollTop: location }, 1)
                console.log(location);
            }

        }
    },
    /*************** search combobox ******************/
    jqcombobox: function (selectorelement) {
        $.widget("custom.combobox", {
            _create: function () {
                this.wrapper = $("<span>")
                    .addClass("custom-combobox")
                    .insertAfter(this.element);

                this.element.hide();
                this._createAutocomplete();
                this._createShowAllButton();
            },

            _createAutocomplete: function () {
                var selected = this.element.children(":selected"),
                    value = selected.val() ? selected.text() : "";

                this.input = $("<input>")
                    .appendTo(this.wrapper)
                    .val(value)
                    .attr("title", "")
                    .addClass(
                        "custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left"
                    )
                    .autocomplete({
                        delay: 0,
                        minLength: 0,
                        source: $.proxy(this, "_source")
                    })
                    .tooltip({
                        classes: {
                            "ui-tooltip": "ui-state-highlight"
                        }
                    });

                this._on(this.input, {
                    autocompleteselect: function (event, ui) {
                        ui.item.option.selected = true;
                        this._trigger("select", event, {
                            item: ui.item.option
                        });
                    },

                    autocompletechange: "_removeIfInvalid"
                });
            },

            _createShowAllButton: function () {
                var input = this.input,
                    wasOpen = false;

                $("<a>")
                    .attr("tabIndex", -1)
                    // .attr("title", "Show All Items")
                    .attr("spellcheck", "false")
                    .tooltip()
                    .appendTo(this.wrapper)
                    .button({
                        icons: {
                            primary: "ui-icon-triangle-1-s"
                        },
                        text: false
                    })
                    .removeClass("ui-corner-all")
                    .addClass("custom-combobox-toggle ui-corner-right")
                    .on("mousedown", function () {
                        wasOpen = input.autocomplete("widget").is(":visible");
                    })
                    .on("click", function () {
                        input.trigger("focus");

                        // Close if already visible
                        if (wasOpen) {
                            return;
                        }

                        // Pass empty string as value to search for, displaying all results
                        input.autocomplete("search", "");
                    });
            },

            _source: function (request, response) {
                var matcher = new RegExp(
                    $.ui.autocomplete.escapeRegex(request.term),
                    "i"
                );
                response(
                    this.element.children("option").map(function () {
                        var text = $(this).text();
                        if (this.value && (!request.term || matcher.test(text)))
                            return {
                                label: text,
                                value: text,
                                option: this
                            };
                    })
                );
            },

            _removeIfInvalid: function (event, ui) {
                // Selected an item, nothing to do
                if (ui.item) {
                    return;
                }

                // Search for a match (case-insensitive)
                var value = this.input.val(),
                    valueLowerCase = value.toLowerCase(),
                    valid = false;
                this.element.children("option").each(function () {
                    if (
                        $(this)
                            .text()
                            .toLowerCase() === valueLowerCase
                    ) {
                        this.selected = valid = true;
                        return false;
                    }
                });

                // Found a match, nothing to do
                if (valid) {
                    return;
                }

                // Remove invalid value
                this.input
                    .val("")
                    // .attr("title", "No results fond. Please try another keyword.")
                    .tooltip("open");
                this.element.val("");
                this._delay(function () {
                    this.input.tooltip("close").attr("title", "");
                }, 2500);
                this.input.autocomplete("instance").term = "";
            },

            _destroy: function () {
                this.wrapper.remove();
                this.element.show();
            }
        });
    },
    //   /**
    // * 将数值四舍五入后格式化.
    // * @param num 数值(Number或者String)
    // * @param cent 要保留的小数位(Number)
    // * @param isThousand 是否需要千分位 0:不需要,1:需要(数值类型);
    // * @return 格式的字符串,如'1,234,567.45'
    // * @type String
    // */
    formatNumber: function (num, cent, isThousand) {
        var sign, cents;
        if (num == "-" || num == undefined) {
            return "-";
        }
        num = num.toString().replace(/\$|\,/g, "");
        // 检查传入数值为数值类型
        if (isNaN(num)) {
            num = "0";
        }
        // 获取符号(正/负数)
        sign = num == (num = Math.abs(num));
        num = Math.floor(num * Math.pow(10, cent) + 0.50000000001); // 把指定的小数位先转换成整数.多余的小数位四舍五入
        cents = num % Math.pow(10, cent); // 求出小数位数值
        num = Math.floor(num / Math.pow(10, cent)).toString(); // 求出整数位数值
        cents = cents.toString(); // 把小数位转换成字符串,以便求小数位长度
        // 补足小数位到指定的位数
        while (cents.length < cent) {
            cents = "0" + cents;
        }
        if (isThousand) {
            // 对整数部分进行千分位格式化.
            for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
                num =
                    num.substring(0, num.length - (4 * i + 3)) +
                    "," +
                    num.substring(num.length - (4 * i + 3));
        }
        if (cent > 0) {
            return (sign ? "" : "-") + num + "." + cents;
        } else {
            return (sign ? "" : "-") + num;
        }
    },
    // 保留n位小数 不四舍五入
    formatNumberNo: function (num, cent) {
        num = num.toString();
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, cent + index + 1)
        } else {
            num = num.substring(0)
        }
        return parseFloat(num).toFixed(cent)
    },

    /*** 时间加AM PM****/
    timeToAPM: function (update_time) {
        var dateTime = "";
        if (update_time !== "-" || update_time !== "N/A") {
            var time = parseInt(update_time.substring(9, 12));
            if (time > 12) {
                dateTime =
                    update_time.substring(0, 9) +
                    ", " +
                    update_time.substring(9, 15) +
                    " PM";
            } else {
                dateTime =
                    update_time.substring(0, 9) +
                    ", " +
                    update_time.substring(9, 15) +
                    " AM";
            }
        }
        return dateTime;
    },

    /***yyyy-mm-dd 00:00:00 ==> dd JAN yy****/
    en_mon_arr: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ],
    en_mon_arr1: {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
    },
    formatDate (data) {
        var date =
            data.substring(8, 10) +
            " " +
            this.en_mon_arr[Number(data.substring(5, 7)) - 1] +
            " " +
            data.substring(2, 4);
        return date;
    },

    /***yyyy-mm-dd 00:00:00 ==> dd JAN yyyy****/
    formatYearDate (data) {
        var date =
            data.substring(8, 10) +
            " " +
            this.en_mon_arr[Number(data.substring(5, 7)) - 1] +
            " " +
            data.substring(0, 4);
        return date;
    },

    /*** dd JAN yy ==> yyyy/mm/dd 00:00:00****/
    formatDetailDate (data) {
        var date =
            "20" +
            data.substring(7, 9) +
            "/" +
            this.en_mon_arr1[data.substring(3, 6)] +
            "/" +
            data.substring(0, 2) +
            " 00:00:00";
        return date;
    },

    /**************** 数据为空 为"N/A" ***************/
    spaceData (data) {
        if (!data || data == "" || data == undefined || data == "-") {
            data = "N/A";
        }
        return data;
    },

    /**************** 转换为自己时区 ***************/
    changeToLocalTime (time) {
        if (time.indexOf('/') < 0) {
            if (time.split(' ')[3]) {
                time = `${time.split(' ')[2]}/${this.en_mon_arr1[time.split(' ')[1]]}/${time.split(' ')[0]} ${time.split(' ')[3]}:00`
            } else {
                time = `${time.split(' ')[2]}/${this.en_mon_arr1[time.split(' ')[1]]}/${time.split(' ')[0]} 00:00:00`
            }
        }
        var dateMilliseconds = new Date(time).getTime();
        var timezonemillsecond = -new Date().getTimezoneOffset() * 60000;
        dateMilliseconds = dateMilliseconds + timezonemillsecond;
        return new Date(dateMilliseconds);
    },

    /**************** change正数添加+ ***************/
    changeRate (num) {
        if (num == 0) {
            return num;
        }
        return "+" + num;
    },

    /**************** RM 1.6  ***************/
    formarExercisePrice (data) {
        if (data.indexOf(" ") > -1) {
            return (
                data.split(" ")[0] + " " + this.formatNumber(data.split(" ")[1], 4, 0)
            );
        } else {
            return this.formatNumber(data, 4, 0);
        }
    },

    /**************** 截掉千位后的数值 ***************/
    clearRM (num) {
        if (num && num !== undefined) {
            num = num / 1000;
            return num;
        }
    },
    /*************** input框点清除 ******************/
    clickEliminate (dox, ifOid) {
        let Oid
        $(dox)
            .find("input")
            .on("focus", function () {
                if (ifOid) {
                    Oid = $(this).val()
                } else {
                    if ($(this).val()) {
                        Oid = $(this).val()
                    }
                }
                $(this).val('')
            }).on('blur', function () {
                if (ifOid) {
                    $(this).val(Oid)
                } else {
                    if ($(this).val().length !== 0) {
                        Oid = $(this).val()
                    } else {
                        $(this).val(Oid)
                    }
                }
            })
    },
    // 是input为空
    inputEmpty (id) {
        let oldVal
        $(id).find("input").off('click').on('click', function () {
            oldVal = $(this).val()
            $(this).val('')
        }).off('blur').on('blur', function () {
            if (!oldVal) {
                oldVal = $(this).val()
            }
            $(this).val(oldVal)
        })
    },
    /************table to excel**************/
    export_csv (data, name) {
        if (window.navigator.msSaveOrOpenBlob) {
            //ie
            var blob = new Blob([decodeURIComponent(encodeURI(data))], {
                type: "text/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, name + ".csv" || "temp.csv");
        } else {
            // “\ufeff” BOM头
            var uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(data);
            var downloadLink = document.createElement("a");
            downloadLink.href = uri;
            downloadLink.download = name + ".csv" || "temp.csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    },
    export_table (selector, name) {
        if (document.querySelectorAll(selector).length == 0) {
            alert('Selector "' + selector + '" not exists!');
            return false;
        }
        this.export_csv(this.get_table_data(selector), name);
    },
    get_table_data (selector) {
        var data = "";
        $.each(document.querySelectorAll(selector + " tr"), function (t, v) {
            var tmp = [];
            $.each(v.querySelectorAll("th,td"), function (e, i) {
                tmp.push(i.innerText.replace("\n", "")); // 单元格里含有多个html元素可能会获取到换行符
            });
            data += tmp.join(",") + "\n";
        });
        return data;
    },

    MatrixExport_table (divBoxArr) {
        let _this = this
        var dataText
        divBoxArr.forEach(function (it, index) {
            var i = index + 1;
            if (document.querySelectorAll(it.div).length == 0) {
                dataText += 'Selector "' + it.div + '" not exists!'
            }
            dataText += _this.MatrixGet_table_data(it.div, it.name)
        });
        this.MatrixExport_csv(dataText);
    },

    MatrixGet_table_data (selector, name) {
        var data = 'Name,' + name + '\n';
        $.each(document.querySelectorAll(selector + " tr"), function (t, v) {
            var tmp = [];
            $.each(v.querySelectorAll("th,td"), function (e, i) {
                tmp.push(i.innerText.replace("\n", "")); // 单元格里含有多个html元素可能会获取到换行符
            });
            data += tmp.join(",") + "\n";
        });
        return data;
    },

    MatrixExport_csv (data) {
        if (window.navigator.msSaveOrOpenBlob) {
            //ie
            var blob = new Blob([decodeURIComponent(encodeURI(data))], {
                type: "text/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, "temp.csv");
            console.log(1);
        } else {
            // “\ufeff” BOM头
            var uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(data);
            var downloadLink = document.createElement("a");
            downloadLink.href = uri;
            downloadLink.download = "temp.csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            console.log(2);
        }
    },

    /************过滤**************/
    filtration (data, inputVal) {
        var showList = [];
        var inputV = inputVal;
        inputV = inputVal.replace(" ", "");
        for (var r = 0, max = data.length; r < max; r++) {
            if (data[r].dsply_nmll !== undefined) {
                var liRic
                if (data[r].judge) {
                    liRic = "(" + data[r].dsply_nmll.split(".")[0] + ") " + " (" + data[r].ticker + ") " + data[r].underlying_name;
                } else {
                    liRic = data[r].dsply_nmll.split(".")[0] + " (" + data[r].ticker + "): " + data[r].issuer_name.replace("MACQ", "Macquarie");
                }
                if (
                    liRic.toLowerCase().indexOf(inputV.toLowerCase()) >= 0 ||
                    inputV.toLowerCase().indexOf(liRic.toLowerCase()) >= 0
                ) {
                    var showMx = {
                        liText: liRic,
                        ric: data[r].ric,
                        judge: data[r].judge,
                        name: data[r].dsply_nmll.split(".")[0]
                    };
                    showList.push(showMx);
                }
            }
        }
        return showList;
    },
    blackScholes: blackScholes,

    /**** 判断ric是否存在在details中 return true/false ****/

    geAllricData () {
        //var ricArrst = [];
        $.ajax({
            type: "GET",
            url: this.webservice + "GetRICList",
            data: { token: "webkey" },
            dataType: "json",
            async: false,
            success: function (data) {
                $.each(data.ric, (index, data) => {
                    ricArrst.push(data.ric);
                });
            },
            error: function (error) {
                console.log(error);
            }
        });
    },

    isExistRic (ric) {
        var flag = false;
        //  console.log(this.ricArrst);
        for (var i = 0; i < ricArrst.length; i++) {
            if (ricArrst[i] === ric) {
                flag = true;
                break;
            }
        }
        return flag;
    },

    /**** 判断issue是否是RHB return true/false ****/
    isRHB (value) {
        if (value === "RHB") {
            return true;
        } else {
            return false;
        }
    },

    /********* >1 保留2位  <1保留3位  **********/
    reservedNumber (num, ric) {
        if (num == "-" || num == undefined) {
            return "-";
        } else {
            if (ric == '2823.HK' || ric == '2840.HK' || ric == '3067.HK') {
                if (num < 10) {
                    return this.formatNumber(num, 3, 0);
                } else {
                    return this.formatNumber(num, 2, 0);
                }

            } else {
                if (num > 1) {
                    return this.formatNumber(num, 2, 0);
                } else {
                    return this.formatNumber(num, 3, 0);
                }
            }
        }
    },

    /**************** 打印生成表格 ***************/
    pringtingTable (selector) {
        var headstr = "<html><head><title></title></head><body><table>";
        var footstr = "</table></body>";
        var printData = $(selector).html();
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = headstr + printData + footstr;
        window.print(); //打印
        document.body.innerHTML = oldstr;
        return false;
    },


    /**************** Matrix打印生成表格 ***************/
    MatrixPringtingTable (printData) {
        var headstr = "<html><head><title></title></head><body><table>";
        var footstr = "</table></body>";
        // var printData = $(selector).html();
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = headstr + printData + footstr;
        window.print(); //打印
        document.body.innerHTML = oldstr;
        return false;
    },

    /**************** day / days ***************/
    formatdays (date) {
        if (date <= 1) {
            return date + " day"
        } else if (date > 1) {
            return date + " days"
        } else {
            return "-";
        }
    },

    /**************** 转换为自己时区 ***************/
    // changeToLocalTime(time) {
    //   var dateMilliseconds = new Date(time).getTime();
    //   var timezonemillsecond = -(new Date().getTimezoneOffset()) * 60000;
    //   dateMilliseconds = dateMilliseconds + timezonemillsecond;
    //   return new Date(dateMilliseconds);
    //   Date.prototype.addHours = (h) => {
    //     this.setHours(this.getHours() + h);
    //     return this;
    //   }
    // },

    /********* MACQ 返回全写  **********/
    rewriteIssueName (name) {
        if (name) {
            if (name === "MACQ") {
                return "MACQUARIE";
            } else {
                return name.toUpperCase();
            }
        }
    },

    /********* 货币改动 如果是MYR也返回RM  **********/
    curreryRetrun (currery) {
        if (currery !== undefined) {
            if (currery === "MYR") {
                return "RM";
            } else {
                return currery;
            }
        } else {
            return " ";
        }
    },
    /**************** 转为千位值 ***************/
    kilobitNumber (num) {
        if (parseInt(num).toString().length >= 4) {
            return this.formatNumber(num, 3, 1)
        } else {
            return num
        }
    },

};