<template>
  <div id="app">
    <Header></Header>
    <div id="content" style="overflow: hidden"><router-view /></div>
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
export default {
  name: "App",
  created() {
    this.$nextTick(function() {
      this.$js.ScreenMonitor();
    });
  },
  methods: {},
  components: {
    Header,
    Footer
  }
};
</script>
<style>
/* @import "./fonts.css"; */
/* @import "./assets/css/main.css"; */
</style>
