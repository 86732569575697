import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    // {
    //   path: "/",
    //   name: "Home",
    //   component: Home
    // },
    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue")
    // },
    {
        path: "/",
        redirect: "/my/Home",
        hidden: true,
        meta: {
            title: "RHB Warrants - Home",
            content: {
                keywords: "Malaysia warrants, structured warrants, RHB, Structured warrants, Warrants, Warrant, Hong Kong warrants, Search Warrant, Search Underlying, Daily Warrant",
                description: "The one-stop structured warrants portal in Malaysia."
            }
        }
    },
    {
        path: "/my/Document",
        name: "Document",
        component: () =>
            import ("../views/Document/Document.vue"),
        meta: {
            title: "Warrant Document",
            content: {
                keywords: "listing, prospectus, term sheets, corporate actions",
                description: "Access to our listing documents, prospectus, terms and conditions with respect to the warrants that are issued."
            }
        }
    },
    {
        path: "/my/ExpiringWarrants",
        name: "ExpiringWarrants",
        component: () =>
            import ("../views/ExpiringWarrants/ExpiringWarrants.vue"),
        meta: {
            title: "Expiring Warrants",
            content: {
                keywords: "expiring, RHB, malaysia warrants, Maturing, RHB Warrant",
                description: "Show the expiring structured warrants issued by RHB."
            }
        }
    },
    {
        path: "/my/Home",
        name: "Home",
        component: () =>
            import ("../views/LandingPage/LandingPage.vue"),
        meta: {
            title: "RHB Warrants - Home",
            content: {
                keywords: "Malaysia warrants, structured warrants, RHB",
                description: "The one-stop structured warrants portal in Malaysia."
            }
        }
    },
    {
        path: "/my/LearnWarrants",
        name: "LearnWarrants",
        component: () =>
            import ("../views/LearnWarrants/LearnWarrants.vue"),
        meta: {
            title: "Learn Warrants",
            content: {
                keywords: "warrant education, call warrants, structured warrants, warrants FAQ, Learn Warrants, Put Warrants, Trade Warrant, Warrant Lingo, Warrant Risk, Warrant Value, Cash Settlement, Market Makers",
                description: "Not familiar with warrants? Read below to learn more about structured warrants."
            }
        }
    },
    {
        path: "/my/MarketMovers",
        name: "MarketMovers",
        component: () =>
            import ("../views/MarketMovers/MarketMovers.vue"),
        meta: {
            title: "Market Movers",
            content: {
                keywords: "top traded shares, top traded warrants, liquidity, top gainers, top losers, active, top volume, Best warrants",
                description: "View the top rankings of underlying stocks and warrants."
            }
        }
    },
    // {
    //     path: "/my/UniqueWarrants",
    //     name: "HongKongStockWarrants",
    //     component: () =>
    //         import ("../views/HongKongStockWarrants/HongKongStockWarrants.vue"),
    //     meta: {
    //         title: "Unique Warrants",
    //         content: {
    //             keywords: "Hong Kong Stock, Alibaba, Tencent, Meituan, Xiaomi, Hong Kong warrants, ATMX, Hong Kong Market Making Hours, Hang Seng, Hang Seng Tech, Hong Kong Exchange",
    //             description: "Read to learn more about the warrants of the top technology stocks listed in Hong Kong."
    //         }
    //     }
    // },
    {
        path: "/my/NewWarrants",
        name: "NewWarrants",
        component: () =>
            import ("../views/NewWarrants/NewWarrants.vue"),
        meta: {
            title: "New Warrants",
            content: {
                keywords: "new issue, IPO, RHB, malaysia warrants, New Listing, New Warrants, RHB Warrant",
                description: "Find out new structured warrants issued by RHB."
            }
        }
    },
    {
        path: "/my/Seminar",
        name: "Seminar",
        component: () =>
            import ("../views/Seminar/Seminar.vue"),
        meta: {
            title: "Seminar",
            content: {
                keywords: "call warrants, seminar, malaysia warrants, Webinar, Workshop, Bursa Malaysia",
                description: "See below for the upcoming warrant worskshop."
            }
        }
    },
    {
        path: "/my/SignMeUp",
        name: "SignMeUp",
        component: () =>
            import ("../views/SignMeUp/SignMeUp.vue"),
        meta: {
            title: "Sign me Up",
            content: {
                keywords: "warrant in focus, newsletter, malaysia warrants, subscribe, RHB Warrant, Free, Daily news",
                description: "Sign up for the free subscription of the daily warrant in focus newsletter."
            }
        }
    },
    {
        path: "/my/UnderlyingChart",
        name: "UnderlyingChart",
        component: () =>
            import ("../views/UnderlyingChart/UnderlyingChart.vue"),
        meta: {
            title: "Underlying Chart",
            content: {
                keywords: "underlying charts, technical analysis, traded volume, Price Chart, Daily Chart, Weekly Chart, Monthly Chart, Yearly Chart, ",
                description: "Use underlying charts to study the technical analysis of the warrant's underlying."
            }
        }
    },
    {
        path: "/my/WarrantDetails",
        name: "WarrantDetails",
        component: () =>
            import ("../views/WarrantDetails/WarrantDetails.vue"),
        meta: {
            title: "Warrant Details",
            content: {
                keywords: "warrant details, warrant terms, RHB, malaysia warrants, Ambank, Kenanga, Macquarie, CIMB, Maybank, Call Warrant, Put Warrant, Chart,",
                description: "Find all the information you need to know about  the selected structured warrant"
            }
        }
    },
    {
        path: "/my/WarrantInFocus",
        name: "WarrantInFocus",
        component: () =>
            import ("../views/WarrantInFocus/WarrantInFocus.vue"),
        meta: {
            title: "Warrants in Focus",
            content: {
                keywords: "daily market highlights, stock market, research, newsletter, warrant market, warrant ideas, stock ideas, RHB Research, RHB warrant, Articles, write ups",
                description: "Never going to miss any important news - subscribe to our daily Warrant in Focus now!"
            }
        }
    },
    {
        path: "/my/WarrantMatrix",
        name: "WarrantMatrix",
        component: () =>
            import ("../views/WarrantMatrix/WarrantMatrix.vue"),
        meta: {
            title: "Warrant Matrix",
            content: {
                keywords: "price matrix, live matrix, live pricing, bid price, Malaysia warrants, RHB, Warrant Matrix, Ask Price, Hong Kong Warrants, Share Price, Warrant Price, Pricing model",
                description: "Use our live matrix to make trading warrants easy. See where our bid prices for index and single stock structured warrants will be throughout the day."
            }
        }
    },
    {
        path: "/my/WarrantOfTheDay",
        name: "WarrantOfTheDay",
        component: () =>
            import ("../views/WarrantOfTheDay/WarrantOfTheDay.vue"),
        meta: {
            title: "Warrants of the Day",
            content: {
                keywords: "top pick warrants, trending warrants, hot warrants",
                description: "Explore what warrants are currently hot in the market."
            }
        }
    },
    {
        path: "/my/WarrantSearch",
        name: "WarrantSearch",
        component: () =>
            import ("../views/WarrantSearch/WarrantSearch.vue"),
        meta: {
            title: "Warrant Search",
            content: {
                keywords: "warrant search, investment ideas, investment criteria, Malaysia warrants, RHB, Ambank, Kenanga, Macquarie, CIMB, Maybank, Hong Kong warrants, Hong Kong warrants, Warrant Price, Traded Volume, Market Information, Warrant Information",
                description: "Use our warrant search tool to find the right warrant to invest, according to your own criteria."
            }
        }
    },
    {
        path: "/my/WarrantShout-Out",
        name: "WarrantShout-Out",
        component: () =>
            import ("../views/WarrantShout-Out/WarrantShout-Out.vue"),
        meta: {
            title: "Warrant Shout-Out",
            content: {
                keywords: "announcement, shout-out, RHB, malaysia warrants, Sold Out, RHB Warrant",
                description: "Read our latest announcements to get ahead of what's happening in the warrant space."
            }
        }
    },
    {
        path: "/my/Gallery",
        name: "Gallery",
        component: () =>
            import ("../views/Gallery/Gallery.vue"),
        meta: {
            title: "Gallery",
            content: {
                keywords: "Photos, Videos, RHB, malaysia warrants, RHB Warrant",
                description: "Visit our multimedia gallery to take a look at our recent events."
            }
        }
    },
    {
        path: "/my/Campaign",
        name: "Campaign",
        component: () =>
            import ("../views/Campaign/Campaign.vue"),
        meta: {
            title: "Campaign",
            content: {
                keywords: "Campaign, Malaysia Warrants, RHB",
                description: "Learn more about our latest campaigns!"
            }
        }
    },
    {
        path: "/my/WarrantCalculator",
        name: "WarrantCalculator",
        component: () =>
            import ("../views/WarrantCalculator/WarrantCalculator.vue"),
        meta: {
            title: "Warrant Calculator",
            content: {
                keywords: "warrant calculator, warrant terms, RHB, malaysia warrants, Ambank, Kenanga, Macquarie, CIMB, Maybank, Expiry, Cash settlement, Hong Kong warrants, Simulation, TP, Take Profit, Target price, ",
                description: "Use our warrant calculator to estimate your future structured warrant price given a change in underlying price, time to expiry or implied volatility."
            }
        }
    },
    {
        path: "/my/Contactus",
        name: "Contactus",
        component: () =>
            import ("../views/Contactus.vue"),
        meta: {
            title: "Contact Us",
            content: {
                keywords: "contact us, email, phone, Call, Feedback, RHB Warrant",
                description: "Get in touch and let us know how we can assist."
            }
        }
    },
    {
        path: "/my/Disclaimer",
        name: "Disclaimer",
        component: () =>
            import ("../views/Disclaimer.vue"),
        meta: {
            title: "Disclaimer"
        }
    },
    {
        path: "/my/Maintance",
        name: "Maintance",
        component: () =>
            import ("../views/Maintance.vue"),
        meta: {
            title: "RHB Warrants - Home"
        }
    },
    {
        path: "/my/PersonalActPDPA",
        name: "PersonalActPDPA",
        component: () =>
            import ("../views/PersonalActPDPA.vue"),
        meta: {
            title: "Personal Act"
        }
    },
    {
        path: "/my/WebinarTerms",
        name: "WebinarTerms",
        component: () =>
            import ("../views/WebinarTerms.vue"),
        meta: {
            title: "Webinar terms & conditions"
        }
    },
    {
        path: "/my/Video",
        name: "Video",
        component: () =>
            import ("../views/SuccessSpeaks/SuccessSpeaks.vue"),
        meta: {
            title: "Video"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;